import { StreamActions } from "@hotwired/turbo"

StreamActions.fetch = async function () {
  const url = this.getAttribute("url")
  if (!url) {
    console.error("Missing URL for Turbo Stream fetch action")
    return
  }

  try {
    const response = await fetch(url, { headers: { "Content-Type": "text/vnd.turbo-stream.html" } })
    if (response.ok) {
      const turboStream = await response.text()
      // insert the fetched Turbo Stream content at the end of the target
      document.documentElement.insertAdjacentHTML("beforeend", turboStream)
    } else {
      console.error("Failed to fetch Turbo Stream content:", response)
    }
  } catch (error) {
    console.error("Error fetching Turbo Stream content:", error)
  }
}
