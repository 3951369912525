import { StreamActions } from "@hotwired/turbo"

// Custom turbo stream action which highlights the target or targets with a yellow background that then fades
StreamActions.highlight = async function () {
  const targetId = this.getAttribute("target")
  const targetSelector = this.getAttribute("targets")

  if (!targetId && !targetSelector) {
    console.error("Missing target or targets for Turbo Stream highlight action")
    return
  }

  let targetElements = []
  if (targetId) {
    const targetElement = document.getElementById(targetId)
    if (targetElement) {
      targetElements.push(targetElement)
    }
  } else if (targetSelector) {
    targetElements = Array.from(document.querySelectorAll(targetSelector))
  }

  targetElements.forEach((element) => {
    // Add Tailwind classes for transition
    element.classList.add("transition-colors", "duration-1000")

    // Temporarily add the highlight color
    element.classList.add("bg-yellow-200")

    // Remove the highlight color after a delay to transition back
    setTimeout(() => {
      element.classList.remove("bg-yellow-200")
    }, 1000)
  })
}
